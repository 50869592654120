import React from 'react';
import { 
    Container,
    Col, 
    Row,
    Button
} from 'react-bootstrap';

function Consultation(props) {

    return (
      <Container className="px-5 py-2 text-center" id={props.id}>
          <Row>
            <Col className="text-primary">
                <h1><span className="">Bingung, Punya Pertanyaan?</span></h1>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col className="mt-4">
                <Button 
                  className="p-3" 
                  variant="primary" 
                  size="lg"
                  href={process.env.REACT_APP_WA_NUMBER }>KONSULTASI SEKARANG</Button>
            </Col>
          </Row>
      </Container>
    );
  }
  
  export default Consultation;
  