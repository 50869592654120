import React from 'react';
import { 
    Container,
    Col, 
    Row
} from 'react-bootstrap';
import PriceItem from './PriceItem';
import FeatureItem from './FeatureItem';


function Prices(props) {
    return (
      <Container className="p-3 p-md-5" id={props.id}>
          <Row>
            <Col className="text-center text-dark">
                <h1><span className="border-primary border-highlight">Harga</span></h1>
            </Col>
          </Row>
          <Row className="text-center m-0 mt-md-5">
            <Col className="mt-4 border border-secondary" xs="12" lg="6">
                <PriceItem 
                    isFirst={true}
                    title={"Paket Dasar"}
                    price={"Rp49.999"} 
                    message={"Gan, Saya mau pesan Paket Dasar, bisa?"}>

                    <Container className="my-4 text-center">
                        <FeatureItem title="Visualisasi 3D" />
                        <FeatureItem title="Denah" />
                        <FeatureItem title="Tampak" />
                        <FeatureItem title="Potongan" />
                        <FeatureItem title="Detail Kamar Mandi dan Toilet" />
                        <FeatureItem title="Denah & Detail Pola Lantai" />
                        <FeatureItem title="Kusen" />
                        <FeatureItem title="Plafon" />
                        <FeatureItem title="Atap" />
                        <FeatureItem title="Instalasi Titik Lampu" />
                        <FeatureItem title="Instalasi Air Bersih, Air Kotor dan Hujan" />
                        <FeatureItem title="Detail Sumur Resapan, Septic Tank & Bak Kontrol" />
                    </Container>
                </PriceItem>
            </Col>

            <Col className="mt-4 border border-dark" xs="12" lg="6">
                <PriceItem 
                    title={"Paket Lengkap"}
                    price={"Rp99.999"} 
                    message={"Gan, Saya mau pesan Paket Lengkap, bisa?"}>

                    <Container className="my-4 text-center">
                        <FeatureItem title="Visualisasi 3D" />
                        <FeatureItem title="Denah" />
                        <FeatureItem title="Tampak" />
                        <FeatureItem title="Potongan" />
                        <FeatureItem title="Detail Kamar Mandi dan Toilet" />
                        <FeatureItem title="Denah & Detail Pola Lantai" />
                        <FeatureItem title="Kusen" />
                        <FeatureItem title="Plafon" />
                        <FeatureItem title="Atap" />
                        <FeatureItem title="Instalasi Titik Lampu" />
                        <FeatureItem title="Instalasi Air Bersih, Air Kotor dan Hujan" />
                        <FeatureItem title="Detail Sumur Resapan, Septic Tank & Bak Kontrol" />
                        <FeatureItem title="Denah Interior (2D)" />
                        <FeatureItem title="Detail Potongan Interior (2D)" />
                        <FeatureItem title="3D Render (2View/Ruang)" />
                        <FeatureItem title="Detail Dimensi Furniture (3D)" />
                        <FeatureItem title="Detail Ornamen (3D) BONUS Video Animasi Eksterior dan Interior" />
                    </Container>
                </PriceItem>
            </Col>
          </Row>
      </Container>
    );
  }
  
  export default Prices;
  