import React from 'react';
import { 
    Col, 
    Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function FeatureItem(props) {
    return (
        <Row className="mb-3">
            <Col>
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />  <span className="font-weight-bold" >{props.title}</span>
            </Col>
        </Row>
    );
  }
  
  export default FeatureItem;
  