import React from 'react';
import { 
    Container,
    Col, 
    Row
} from 'react-bootstrap';
import BenefitItem from './BenefitItem';
import { faClock, faUserClock, faClipboardCheck, faMoneyCheck } from '@fortawesome/free-solid-svg-icons'

function Benefits(props) {

    return (
      <Container className="p-5 text-center text-md-left" id={props.id}>
          <Row>
            <Col className="text-dark">
                <h1><span className="border-primary border-highlight">Keunggulan</span></h1>
            </Col>
          </Row>
          <Row className="text-center mt-5">
            <Col className="mt-4" xs="12" md="6">
                <BenefitItem 
                    icon={faClock}
                    title={"Tepat Waktu"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."} />
            </Col>

            <Col className="mt-4" xs="12" md="6">
                <BenefitItem 
                    icon={faUserClock}
                    title={"Dukungan Penuh"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."} />
            </Col>

            <Col className="mt-4" xs="12" md="6">
                <BenefitItem 
                    icon={faClipboardCheck}
                    title={"Kualitas Bagus"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."} />
            </Col>

            <Col className="mt-4" xs="12" md="6">
                <BenefitItem 
                    icon={faMoneyCheck}
                    title={"Harga Terjangkau"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."} />
            </Col>
          </Row>
      </Container>
    );
  }
  
  export default Benefits;
  