import React, { useState } from 'react';
import { 
  Container,
  Col, 
  Row,
  Carousel
} from 'react-bootstrap';

import CarouselReactImages, { Modal, ModalGateway } from 'react-images';

import livingRoom from '../assets/white-living-room-90.jpg';
import livingRoom2 from '../assets/white-living-room-90-2.jpg';

const images = [
  { 
    key: "0", 
    image: livingRoom
  },
  {
    key: "1",
    image: livingRoom2
  }
];



function Portofolios(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);
    const [cursorMode, setCursorMode] = useState("default");
    const imageViewList = getImageViewList();

    const cursorStyle = {
      cursor: cursorMode
    }

    function onImageSelected(index)
    {
      setSelectedImage(parseInt(index));
      setIsModalOpen(true);
    }

    function onImageViewClosed()
    {
      setIsModalOpen(false);
    }
    return (
      <Container className="p-5 text-center" id={props.id}>
        <Row>
          <Col className="text-dark">
              <h1><span className="border-primary border-highlight">Portofolio</span></h1>
          </Col>
        </Row>
        <Row className="text-center mt-5">
          <Col className="mt-4">
            <Carousel>
              {images.map(item => {
                  return (
                    <Carousel.Item key={item.key}>
                    <img
                        className="d-block w-100"
                        src={item.image}
                        alt="First slide"
                        fluid
                        onClick={() => onImageSelected(item.key)}
                        onMouseEnter={() => setCursorMode("pointer")}
                        onMouseLeave={() => setCursorMode("default")}
                        style={cursorStyle}
                    />
                    </Carousel.Item>
                  );
              })
              }
            </Carousel>

            {isModalOpen && 
              <ModalGateway>
                <Modal onClose={onImageViewClosed}>
                  <CarouselReactImages 
                    currentIndex={selectedImage}
                    views={imageViewList} />
                </Modal>
              </ModalGateway> }
          </Col>
        </Row>
      </Container>
    );
  }

  function getImageViewList()
  {
    const result = [];
    for(var i = 0; i < images.length; i++)
    {
      result.push({ source: images[i].image } );
    }
    return result;
  }
  
  export default Portofolios;
  