import React from 'react';
import { 
    Container,
    Col, 
    Row
} from 'react-bootstrap';

function Footer(props) {
    return (
      <Container className="bg-dark p-5" fluid id={props.id}>
          <Row>
                <Col xs="12" md="6">
                    <Row>
                        <Col className="text-white">
                            <h3><span className="border-primary border-highlight">Sosial Media</span></h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-white mt-4">
                            <Row>
                                <Col>
                                    <a href="#" className="text-white">Facebook</a>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col>
                                    <a href="#" className="text-white">Instagram</a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col xs="12" md="6">
                    <Row>
                        <Col className="text-white mt-4 mt-md-0">
                            <h3><span className="border-primary border-highlight">Kontak</span></h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-white mt-4">
                            <Row>
                                <Col>
                                    <span className="font-weight-bold">Alamat:</span> Makassar - Indonesia
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <span className="font-weight-bold">Email:</span> info@polarisindodesain.com
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col>
                                    <span className="font-weight-bold">Telepon:</span> (+62 411) 584270
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center text-muted">
                © 2020 polarisindodesain.com
            </Col>
          </Row>
      </Container>
    );
  }
  
  export default Footer;
  