import React from 'react';
import { 
    Col, 
    Row,
    Button
} from 'react-bootstrap';

function PriceItem(props) {    
    const waUrl = encodeURI(`${process.env.REACT_APP_WA_NUMBER}?text=${props.message}`);
    return (
        <Row>
            <Col>
                <Row className="bg-primary">
                    <Col className="text-white font-weight-bold p-4">
                        <h3>{props.title}</h3>
                    </Col>
                </Row>
                <Row className="bg-light">
                    <Col className="text-info p-4">
                        <h1>{props.price}</h1>
                    </Col>
                </Row>           

                <Row className="mt-2 mb-4">
                    <Col>
                        <Button href={waUrl} className="p-3">PESAN SEKARANG</Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {props.children}
                    </Col>
                </Row> 
            </Col>
        </Row>
    );
  }
  
  export default PriceItem;
  