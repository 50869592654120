import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.scss';
import { 
  Container,
  Nav,
  Navbar, 
  Carousel
} from 'react-bootstrap';
import livingRoom from './assets/white-living-room-90.jpg';
import livingRoom2 from './assets/white-living-room-90-2.jpg';
import Footer from './components/Footer';
import Services from './components/Services';
import Benefits from './components/Benefits';
import Prices from './components/Prices';
import Consultation from './components/Consultation';
import Portofolios from './components/Portofolios';


const navbarItems = ["home", "services", "benefits", "prices", "portofolios", "contact"];
const carouselStyle = {
  height: 500
};

function App() {
  const currentSection = getCurrentSectionId();
  const [activeTab, setActiveTab] = useState(getCurrentSectionId());
  const [navbarState, setNavbarState] = useState(isNavbarStateTrue());
  

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    function onScroll() 
    {
      const currentNavbarState = isNavbarStateTrue();
      if(currentNavbarState != navbarState) 
      {
        setNavbarState(currentNavbarState);
      }      

      const currentSectionId = getCurrentSectionId();
      if(activeTab != currentSectionId)
        setActiveTab(currentSectionId);
    }

    return function cleanUp()
    {
      window.removeEventListener('scroll', onScroll);
    }
  });
  
  const mainNavbarClassName = `${navbarState ? "text-white" : "text-primary"} px-5`;
  return (
    <div className="App">
      <div className="d-none d-md-block">
        <Navbar fixed="top" 
          bg={ navbarState ? "transparent" : "white" }
          variant={ navbarState ? "dark" : "light" }
          className={ mainNavbarClassName }
          id="main-nav-bar">
          <Navbar.Brand href="#home">Logo Here</Navbar.Brand>
          <Container>
            <Nav className="ml-auto" activeKey={activeTab} >
              <Nav.Link className="mr-4" href="#home">Home</Nav.Link>
              <Nav.Link className="mr-4" href="#services">Layanan</Nav.Link>
              <Nav.Link className="mr-4" href="#benefits">Keunggulan</Nav.Link>
              <Nav.Link className="mr-4" href="#prices">Harga</Nav.Link>
              <Nav.Link className="mr-4" href="#portofolios">Portofolio</Nav.Link>
              <Nav.Link className="mr" href="#contact">Kontak</Nav.Link>
            </Nav>
          </Container>  
        </Navbar>
      </div>
      
      <Carousel id="home">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={livingRoom}
            alt="First slide"
            fluid
          />
          <Carousel.Caption>
            <h3>BERKUALITAS</h3>
            <p>DIBUAT OLEH ORANG YANG BERPENGALAMAN DIBIDANGNYA</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={livingRoom2}
            alt="Second slide"
            fluid
          />
          <Carousel.Caption>
            <h3>HARGA TERJANGKAU</h3>
            <p>BISA DISESUAKAN DENGAN ANGGARANMU</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <Services id="services" />
      <Benefits id="benefits" />
      <Prices id="prices" />
      <Consultation />
      <Portofolios id="portofolios" />
      <Footer id="contact" />      
    </div>
  );
}

function getCurrentSectionId()
{
  var resultId = `#${navbarItems[0]}`;
  navbarItems.forEach(item => {
    const navbarItem = document.getElementById(item);
    if(navbarItem && document.documentElement.scrollTop + 5 >= navbarItem.offsetTop)
    {
      resultId = `#${item}`;      
      return;
    }
  });

  if(document.documentElement.scrollTop !== 0 && document.documentElement.scrollHeight - document.documentElement.scrollTop === document.documentElement.clientHeight)
    resultId = `#${navbarItems[navbarItems.length - 1]}`;

  return resultId;
}

function scrollTo(elementId)
{
  console.log(elementId);
}

function isNavbarStateTrue()
{
  const homeElement = document.getElementById("home");
  const height = homeElement ? homeElement.offsetHeight : 200;
  const changeStatePos = height - 50;
  return document.documentElement.scrollTop <= changeStatePos;
}

export default App;
