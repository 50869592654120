import React from 'react';
import { 
    Container,
    Col, 
    Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function BenefitItem(props) {
    return (
        <Row>
            <Col xs="12" md="3" className="text-primary d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={props.icon} size="4x"/>
            </Col>
            <Col xs="12" md="9" className="text-center text-md-left">
                <Row className="mt-2">
                    <Col className="text-dark">
                        <h3>{props.title}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-muted">
                        {props.description}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
  }
  
  export default BenefitItem;
  