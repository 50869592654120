import React from 'react';
import { 
    Container,
    Col, 
    Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ServiceItem(props) {
    return (
        <Row className="text-center">
            <Col>
                <Row>
                    <Col className="text-primary">
                        <FontAwesomeIcon icon={props.icon} size="3x" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="text-dark">
                        <h3>{props.title}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-muted">
                        {props.description}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
  }
  
  export default ServiceItem;
  