import React from 'react';
import { 
    Container,
    Col, 
    Row
} from 'react-bootstrap';
import ServiceItem from './ServiceItem';
import { faHome, faBuilding, faHospital } from '@fortawesome/free-solid-svg-icons'


function Services(props) {

    return (
      <Container className="p-0 p-md-5" id={props.id}>
          <Row>
            <Col className="text-center text-dark">
                <h1><span className="border-primary border-highlight">Layanan</span></h1>
            </Col>
          </Row>
          <Row className="text-center m-0 mt-md-5">
            <Col className="mt-4" xs="12" lg="4">
                <ServiceItem 
                    icon={faHome}
                    title={"Desain Rumah"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."} />
            </Col>

            <Col className="mt-4" xs="12" lg="4">
                <ServiceItem 
                    icon={faBuilding}
                    title={"Desain Kantor"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."} />
            </Col>

            <Col className="mt-4" xs="12" lg="4">
                <ServiceItem 
                    icon={faHospital}
                    title={"Desain Rumah Sakit"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."} />
            </Col>
          </Row>
      </Container>
    );
  }
  
  export default Services;
  